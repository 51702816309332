import React from "react";
import { H4, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { PeriodPicker, PeriodPickerValue, PeriodType } from "./period-picker";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Period picker",
	notepad: "https://hackmd.io/xHKW4tbtTiqWpz2SGtP5aA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Period picker"
			subTitle="Allow users to select a range of dates quickly and intuitively."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={PeriodPicker} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<b>Default</b>: Button shows selected range with presets available. Always has a default
					presets with the most commonly used date range.
				</Paragraph>
				<Paragraph>
					<b>Period picker consists of two main elements</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Button (with clock icon) using two lines:
							<Ul
								items={[
									<>Line 1: Active preset or custom date range.</>,
									<>Line 2: Start and end dates of selected range</>,
								]}
							/>
						</>,
						<>
							Popover (triggered by button click) with:
							<Ul
								items={[
									<>Preset tabs (e.g., "Last 7 Days," "Last Month")</>,
									<>Interactive calendar view for custom range selection</>,
								]}
							/>
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Usage with hidden presets</Header.H3>
				<Paragraph>Used when preset data is unavailable, focusing on custom selection.</Paragraph>
				<Example fn={UsageWithHiddenPresets} />
				<Header.H3>Usage with condensed variant</Header.H3>
				<Paragraph>Button (with clock icon) displaying selected range in one line.</Paragraph>
				<Example fn={UsageWithCondensed} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={PeriodPicker}
					initialProps={({ setState }) => ({
						value: {
							start: new Date(2023, 4, 1),
							end: new Date(2023, 4, 31),
							type: PeriodType.Custom,
						},
						onChange: (item) => setState({ value: item }),
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>PeriodPicker</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								Users need to define a specific date range for filtering, reporting, or analysis. .
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>PeriodPicker</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								Form: Select time period for report progress (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FDashboard%2F5765905975%2F1352515706%2F6974477829%2FCustom%2FReports%2FAddReportScheduleFM%3Flang%3Den-US">
									Edit scheduled report
								</a>
								)
							</>,
							<>
								Filter: Filter data in charts to track progress of issues (e.g{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F446001%2FNextGen%2FOverview%3FpageSegments%3D%26conformance%3D0%252C1%252C2%252C3%252C4%26siteTargetIssueKinds%3D1%252C2%26wcagVersion%3D22%26siteTarget.wcagVersion%3D22%26siteTarget.conformanceLevels%3D0%252C1%252C2%252C3%252C4%26siteTarget.issueKinds%3D1%252C2%26lang%3Den-US">
									Accessibility overview
								</a>
								)
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your{" "}
								<Code>PeriodPicker</Code> to existing components for visual consistency.
							</>,
							<>
								Always offer a logical default range (e.g., "Last 7 Days") to streamline common
								tasks.
							</>,
							<>Visually indicate the active range at all times.</>,
							<>
								Visually connect the selected dates within the calendar view to the button's
								display.
							</>,
							<>
								Prevent users from selecting invalid ranges (e.g., end date before start date).
								Provide clear error messages if needed.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Users only need to select a single date (use a{" "}
								<a href="https://fancy.siteimprove.com/lab/components/Actions%20and%20controls/Date%20picker">
									Date picker
								</a>{" "}
								instead).
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul items={[<>Clear labels and instructions.</>]} />
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={[<>Use concise labels for presets (e.g., "Last Month").</>]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore [a11y] */
const BasicUsage = () => {
	const [period, setPeriod] = React.useState<PeriodPickerValue | null>({
		start: new Date(2023, 4, 1),
		end: new Date(2023, 4, 31),
		type: PeriodType.Custom,
	});

	return <PeriodPicker aria-label="Select period" value={period} onChange={setPeriod} />;
};

/**
 * @click button
 * @ignore [a11y]
 * */
const UsageWithHiddenPresets = () => {
	const [period, setPeriod] = React.useState<PeriodPickerValue | null>({
		start: new Date(2023, 4, 1),
		end: new Date(2023, 4, 31),
		type: PeriodType.Custom,
	});

	return (
		<PeriodPicker
			aria-label="Select period"
			value={period}
			onChange={setPeriod}
			hiddenPresets={[
				PeriodType.LastSevenDays,
				PeriodType.LastFourteenDays,
				PeriodType.LastThirtyDays,
				PeriodType.Last365Days,
			]}
		/>
	);
};

/**
 * @ignore [a11y]
 * */
const UsageWithCondensed = () => {
	const [period, setPeriod] = React.useState<PeriodPickerValue | null>({
		start: new Date(2023, 4, 1),
		end: new Date(2023, 4, 31),
		type: PeriodType.Custom,
	});

	return <PeriodPicker aria-label="Select period" value={period} onChange={setPeriod} condensed />;
};
